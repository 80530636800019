import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Host, HostListener, Inject, Input, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
@Directive({
    selector: '[amaTooltip]',
    standalone: true
})
export class TooltipDirective {
  @Input('amaTooltip') public tooltipTitle: string;
  @Input() public tooltipPlacement: string;
  @Input() public tooltipStayOpen: boolean;
  @Input() public tooltipCta: boolean;
  @Input() public tooltipLink: HTMLElement;
  @Input() public closeTipIcon: HTMLElement;
  public tooltip: HTMLElement;
  public offset = 18;
  constructor(private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document) { }


    @HostListener('mouseenter') public onMouseEnter() {
      if (!this.tooltip) {
        this.show();
      }
    }

    @HostListener('mouseleave') public onMouseLeave() {
      if (this.tooltip) {
        this.hide();
      }
    }

  public show() {
    this.create();
    this.setPosition();
  }

  public createTooltipIcon() {
    this.closeTipIcon = this.document.createElement('div');
    this.renderer.addClass(this.closeTipIcon, 'ama__tooltip__icon');
    if (this.tooltipStayOpen) {
       this.renderer.appendChild(this.closeTipIcon, this.renderer.createText("X"));
    }
    this.renderer.listen(this.closeTipIcon, 'click', () => {
      this.hide();
    });
  }

  public createRedirectText() {
    this.tooltipLink = this.document.createElement('span');
    if(this.tooltipCta) {
      this.renderer.appendChild(this.tooltipLink, this.renderer.createText("Can't access?"));
      this.renderer.addClass(this.tooltipLink, 'ama__tooltip__link');
      this.renderer.listen(this.tooltipLink, 'click', () => {
        this.router.navigate(['contact']);
      });
    }
  }

  public hide() {
    this.renderer.removeClass(this.tooltip, 'ama__tooltip--show');

    if (isPlatformBrowser(this.platformId)) {
      window.setTimeout(() => {
        this.renderer.removeChild(this.el.nativeElement, this.tooltip);
        this.tooltip = null;
      });
    }
  }

  public create() {
    this.createRedirectText();
    this.tooltip = this.renderer.createElement('span');
    this.createTooltipIcon();
    this.renderer.appendChild(this.tooltip, this.closeTipIcon);
    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));
    this.renderer.appendChild(this.tooltip, this.tooltipLink);
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.appendChild(this.el.nativeElement, this.tooltip);
    }

    this.renderer.addClass(this.tooltip, 'ama__tooltip');
    this.renderer.addClass(this.tooltip, `ama__tooltip--${ this.tooltipPlacement }`);
  }

  public setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();

    let top: any;
    let left: any;

    if (this.tooltipPlacement === 'top') {
      top = (hostPos.top - (tooltipPos.top + tooltipPos.height) - this.offset / 2);
      left = -(hostPos.width / 2);
    }

    if (this.tooltipPlacement === 'bottom') {
      top = (hostPos.top - (tooltipPos.top - tooltipPos.height) - this.offset);
      left = -(hostPos.width / 2);
    }

    if (this.tooltipPlacement === 'left') {
      top = ((hostPos.height / 2 + (tooltipPos.top - tooltipPos.height / 2)) - hostPos.top);
      left = -(tooltipPos.width + this.offset / 2) ;
    }

    if (this.tooltipPlacement === 'right') {
      top = ((hostPos.height / 2 + (tooltipPos.top - tooltipPos.height / 2)) - hostPos.top);
      left = hostPos.width + this.offset / 2;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${ top }px`);
    this.renderer.setStyle(this.tooltip, 'left', `${ left }px`);
  }
}
